import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleRight,
  faHeadset,
  faChartLine,
  faSearchDollar,
  faCoins,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";
import "../../static/css/service.css";

const Service = (props) => (
  <div className="col-sm-12 col-md-3">
    <div className="service-container">
      <figure className="service-icon">
        <FontAwesomeIcon
          icon={
            props.id === 10
              ? faCoins
              : props.id === 11
              ? faHeadset
              : props.id === 12
              ? faChartLine
              : faSearchDollar
          }
          style={{
            transition: "all 0.5s ease-in-out",
            color: "#de3026",
            fontSize: "3rem",
          }}
        />
      </figure>
      <div className="service-title">
        <h3>{props.name}</h3>
      </div>
      <div className="service-desc">
        <p>{props.description}</p>
      </div>
      <div className="service-button">
        <a href="/our-services">
          <span className="button-text">read more </span>
          <span className="button-icon">
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </span>
        </a>
      </div>
    </div>
  </div>
);

export default withRouter(Service);
