import "../../static/css/whoweare.css";
import ChartImage from "../../static/images/chart.png";
import ChartImageSmall from "../../static/images/ICM-Chart-150x150.png";
import ChartImageMedium from "../../static/images/ICM-Chart-300x300.png";
import ChartImageLarge from "../../static/images/ICM-Chart-360x360.png";

const WhoWeAre = () => (
  <section className="who-we-are-section">
    <div className="container">
      <div className="row">
        <div className="col-md-4 chart-section-chart">
          <div className="chart-image">
            <img
              width="600"
              height="600"
              src={ChartImage}
              className="attachment-large size-large"
              alt="chart"
              srcSet={`${ChartImage} 600w, ${ChartImageSmall} 150w, ${ChartImageMedium} 300w, ${ChartImageLarge} 360w`}
              sizes="(max-width: 600px) 100vw, 600px"
            />
          </div>
        </div>
        <div className="col-md-8 text-section">
          <div className="heading add-margin-bottom">
            <h3>Who we are</h3>
          </div>
          <div className="initial">
            <p>
              Twinjays Ltd is a Commercial Debt Collection Agency based in
              Nairobi, Kenya. FIL has a combined total of over 20 years of
              hands-on debt collection experience through its key staff and
              management personnel, and directors. We are dedicated to be your
              full service debt collection solution by increasing the percentage
              of bad debt recovery for our clients in a professional manner, and
              preserving our client’s image and our reputation in our industry.
            </p>
          </div>
          <div className="middle">
            <p>
              We handle commercial debt of all kind and we shall thus help you
              manage your receivables effectively. We have no up-front fees or
              signup charges for our collection services. We only receive a fee
              if we are able to collect money on the account(s) you place for
              collection. If we do not collect we will not charge a fee for our
              services.
            </p>
          </div>
          <div className="end">
            <p>
              We use technology extensively which keeps us a step ahead always{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default WhoWeAre;
