import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons";
import { faGrin } from "@fortawesome/free-regular-svg-icons";
import ethicsContent from "../../siteContent";
import "../../static/css/ethics.css";

const EthicsItem = (props) => (
  <div className="ethics-item col-md-6">
    <div className="ethics-icon add-margin-bottom">
      <FontAwesomeIcon
        icon={props.id === 70 ? faGrin : faHandHoldingUsd}
        style={{
          transition: "all 0.5s ease-in-out",
          color: "#0f5ca1",
          fontSize: "3rem",
        }}
      />
    </div>
    <div className="title add-margin-bottom">
      <h3>{props.title}</h3>
    </div>
    <div className="description">
      <p>{props.description}</p>
    </div>
  </div>
);

const Ethics = () => (
  <section className="ethics-section">
    <div className="container">
      <div className="row">
        {ethicsContent.ethics.map((item) => {
          const { id, title, description } = item;
          return (
            <EthicsItem
              key={id}
              id={id}
              title={title}
              description={description}
            />
          );
        })}
      </div>
    </div>
  </section>
);

export default Ethics;
