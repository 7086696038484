import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Header/Navbar";
import Services from "../components/OurServices/Services";
import Slide from "react-reveal/Slide";

const OurServicesPage = () => (
  <main>
    <Helmet>
      <title>Our Services</title>
      <meta
        name="description"
        content="As a full service nation debt collection agency, we help companies by collecting funds that are due to them from debtors in Kenya, and all over the world. We assist debtors come up with payment plans that will help them get their delinquent accounts paid in full."
      />
    </Helmet>
    <Navbar activePage="OurServicesPage" />
    <Services />
    <Slide bottom>
      <Footer />
    </Slide>
  </main>
);

export default OurServicesPage;
