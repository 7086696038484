import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomePage from "./pages";
import OurServicesPage from "./pages/our-services";
import AboutPage from "./pages/about";
import Contactpage from "./pages/contact";
import CareersPage from "./pages/careers";
import NotFoundPage from "./pages/NotFoundPage";
function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/our-services" component={OurServicesPage} />
        <Route exact path="/about" component={AboutPage} />
        <Route exact path="/contact" component={Contactpage} />
        <Route exact path="/careers" component={CareersPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
}

export default App;
