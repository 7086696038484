import Carousel from "./ClientsCarousel";
import "../../static/css/clients.css";

const Clients = () => {
  return (
    <section className="clients-section">
      <div className="container">
        <div className="clients-content">
          <div className="heading">
            <h3>Our happy clients</h3>
          </div>
          <div className="clients-carousel">
            <Carousel />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
