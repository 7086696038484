import Slide from "react-reveal/Slide";
import "../../static/css/intouch.css";
const Intouch = () => (
  <section className="intouch-section">
    <div className="container">
      <Slide bottom>
        <div className="intouch-content">
          <div className="heading">
            <h2>Debt management is a pain point to you?</h2>
          </div>
          <div className="subtext">
            <p>
              We have a strong experienced team with combined total of over 50
              years hands-on debt collection experience through our key staff,
              management personnel, and directors.
            </p>
          </div>
          <div className="intouch-element intouch-button">
            <a href="/contact">get in touch with us</a>
          </div>
        </div>
      </Slide>
    </div>
  </section>
);

export default Intouch;
