import "../../static/css/career-listing.css";

const Listing = (props) => {
  const { jobHeading, description } = props.jobListing;
  const { summary, details } = description;
  return (
    <div className="listing-section">
      <div className="container">
        <div className="row">
          <div className="sub-heading add-margin-bottom">
            <h3>
              <span>Job title: </span>
              {jobHeading.title}
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="job-heading-content add-margin-bottom">
            {Object.keys(summary).map((desc) => {
              return (
                <p>
                  <strong>{desc}: </strong>
                  {summary[desc]}
                </p>
              );
            })}
          </div>

          <div className="description-content add-margin-bottom">
            {Object.keys(details).map((content) => {
              if (typeof details[content][0] === "string") {
                return (
                  <>
                    <div className="detail-title add-margin-bottom">
                      <strong>{content}</strong>
                    </div>
                    <ul>
                      {details[content].map((item) => {
                        return <li>{item}</li>;
                      })}
                    </ul>
                  </>
                );
              } else {
                return (
                  <>
                    <div className="detail-title add-margin-bottom">
                      <strong>{content}</strong>
                    </div>
                    {details[content].map((item) => {
                      const { title, description } = item;
                      return (
                        <>
                          <p>{title}</p>
                          <ul>
                            {description &&
                              description.map((desc) => {
                                return <li>{desc}</li>;
                              })}
                          </ul>
                        </>
                      );
                    })}
                  </>
                );
              }
            })}
            {
              <div className="job-application">
                <p>
                  <span>{jobHeading.applyText} </span>
                  <em>
                    <strong>{jobHeading.email}</strong>
                  </em>
                </p>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listing;
