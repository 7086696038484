import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import Navbar from "../../components/Header/Navbar";
import Careers from "../../components/Careers";
import Slide from "react-reveal/Slide";

const CareersPage = () => (
  <main>
    <Helmet>
      <title>Careers</title>
      <meta
        name="description"
        content="Join us at Twinjays Ltd. We have a diverse talented team from all over Kenya."
      />
    </Helmet>
    <Navbar activePage="CareersPage" />
    <Careers />
    <Slide bottom>
      <Footer />
    </Slide>
  </main>
);

export default CareersPage;
