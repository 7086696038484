import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import "../../static/css/clients-carousel.css";
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//import images
import AbsaLogo from "../../static/images/absa.png";
import AtlasmaraLogo from "../../static/images/atlasmara.png";
import CoopLogo from "../../static/images/coop.jpg";
import EquityLogo from "../../static/images/equity.jpg";
import FbkLogo from "../../static/images/fbk.png";
import HelbLogo from "../../static/images/helb.jpeg";
import HfcLogo from "../../static/images/hfc.png";
import JefigsLogo from "../../static/images/jefigs.jpg";
import KCBLogo from "../../static/images/kcb.png";
import LavingtonLogo from "../../static/images/lavingtonsecurity.png";
import MyCreditLogo from "../../static/images/mycredit.png";
import NbkLogo from "../../static/images/nbk.png";
import RealPpleLogo from "../../static/images/real-people.png";
import SafaricomSaccoLogo from "../../static/images/safarisacco.png";
import SidianLogo from "../../static/images/sidian.png";
import UnaitasLogo from "../../static/images/unaitas.png";
import WanandegeLogo from "../../static/images/wanandege.png";

const clientsList = {
  items: [
    { id: 1, img: AbsaLogo },
    { id: 2, img: AtlasmaraLogo },
    { id: 3, img: CoopLogo },
    { id: 4, img: EquityLogo },
    { id: 5, img: FbkLogo },
    { id: 6, img: HelbLogo },
    { id: 7, img: HfcLogo },
    { id: 8, img: JefigsLogo },
    { id: 9, img: KCBLogo },
    { id: 10, img: LavingtonLogo },
    { id: 11, img: MyCreditLogo },
    { id: 12, img: NbkLogo },
    { id: 13, img: RealPpleLogo },
    { id: 14, img: SafaricomSaccoLogo },
    { id: 15, img: SidianLogo },
    { id: 16, img: UnaitasLogo },
    { id: 17, img: WanandegeLogo },
  ],
};

const { items } = clientsList;
const ClientsCarousel = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <Carousel
      swipeable={false}
      draggable
      responsive={responsive}
      infinite={true}
      autoPlay
      autoPlaySpeed={5000}
      keyBoardControl={true}
      transitionDuration={500}
      containerClass="carousel-container"
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      {items.map((item) => {
        return (
          <div
            key={item.id}
            className="carousel-div-item"
            style={{
              background: `url(${item.img}) no-repeat center center`,
              borderRadius: "10px",
              height: "250px",
              margin: "1rem",
              transition: "all .3s",
            }}
          ></div>
        );
      })}
    </Carousel>
  );
};

export default ClientsCarousel;
