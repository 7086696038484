import Slide from "react-reveal/Slide";
import "../../static/css/chart.css";
import ChartImage from "../../static/images/chart.png"

const Chart = () => (
  <section className="chart-section">
    <div className="container">
      <Slide bottom>
        <div className="chart-content">
          <div className="row">
            <div className="chart-text col-md-6">
              <div className="chart-element chart-title heading">
                <h3>We save time and money for our clients.</h3>
              </div>
              <div className="chart-element chart-text-content text-content-main">
                <p>
                  Twinjays Ltd is Kenya’s leading Credit Management Firm providing
                  complete debt recovery solutions in Kenya and the larger East
                  African region.
                </p>
              </div>
              <div className="chart-element text-content-secondary">
                <p>
                  We put people first, doing everything professionally to get
                  your money back while still preserving a good relationship
                  with your customer. We combine a customer-centric approach
                  with cutting edge technology solutions to provide great
                  contact management.
                </p>
              </div>
              <div className="chart-button">
                <a href="/about" className="chart-button-read">
                  read more
                </a>
              </div>
            </div>
            <div className="chart-chart col-md-6">
              <div className="chart-image">
                <img
                  width="420"
                  height="420"
                  src={ChartImage}
                  className="attachment-large size-large"
                  alt="chart"
                />
              </div>
            </div>
          </div>
        </div>
      </Slide>
    </div>
  </section>
);

export default Chart;
