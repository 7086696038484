import MissionItem from "./MissionItem";
import missionContent from "../../siteContent";
import "../../static/css/mission.css";

const Mission = () => (
  <section className="mission-section">
    <div className="container">
      <div className="row">
        {missionContent.mission.map((content) => {
          const { id, title, description } = content;
          return (
            <MissionItem key={id} title={title} description={description} />
          );
        })}
      </div>
    </div>
  </section>
);

export default Mission;
