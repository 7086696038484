import "../static/css/not-found.css";
import React from "react";
import { withRouter } from "react-router-dom";
import NotFoundImg from "../static/images/404.svg";
class NotFound extends React.Component {
  constructor(props) {
    super(props);
  }
  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    return (
      <div
        className="not-found-section"
        style={{
          paddingTop: "200px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="not-found-content">
              <div className="not-found-img">
                <img src={NotFoundImg} alt="not found" width="200" />
              </div>
              <div className="not-found-message">
                <p>Oops! We could not find that page.</p>
              </div>
              <div className="not-found-link">
                <button className="submit-form-btn" onClick={this.goBack}>
                  <span>BACK</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(NotFound);
