import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTty, faPeopleCarry } from "@fortawesome/free-solid-svg-icons";
import { faHandshake, faUser } from "@fortawesome/free-regular-svg-icons";
import { faCreativeCommonsZero } from "@fortawesome/free-brands-svg-icons";
import "../../static/css/choice.css";

const Choice = (props) => (
  <div className="col-md-3 col-sm-12">
    <div className="choice-container">
      <figure className="choice-icon add-margin-bottom">
        <FontAwesomeIcon
          icon={
            props.id === 1
              ? faTty
              : props.id === 2
              ? faPeopleCarry
              : props.id === 3
              ? faUser
              : faCreativeCommonsZero
          }
          style={{
            transition: "all 0.5s ease-in-out",
            color: "#0f5ca1",
            fontSize: "2.5rem",
          }}
        />
      </figure>
      <div className="choice-title add-margin-bottom">
        <h2>{props.name}</h2>
      </div>
      <div
        dangerouslySetInnerHTML={props.description}
        className="service-desc add-margin-bottom"
      ></div>
    </div>
  </div>
);

export default Choice;
