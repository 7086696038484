import footerContent from "../siteContent";
import Logo from "../static/images/logo.jpg";
import "../static/css/footer.css";
const Footer = () => (
  <footer>
    <div className="footer-content">
      <div className="container">
        <div className="row">
          <div className="footer-element col-md-4 company">
            <div className="logo">
              <p>
                <img
                  className="footer-logo"
                  src={Logo}
                  alt="footer logo"
                  width="80"
                />
              </p>
            </div>
            <div className="slogan add-margin-bottom">
              <p>{footerContent.slogan}</p>
            </div>
            <div className="copyright add-margin-bottom">
              <p>{footerContent.copyright}</p>
            </div>
          </div>
          <div className="footer-element col-md-4 contactus">
            <div className="heading add-margin-bottom">
              <h4>Contact us</h4>
            </div>
            <div className="contacts-content">
              <p>
                <span className="title">Email: </span>
                {footerContent.email}
              </p>
              <p>
                <span className="title">Phone: </span>
                {footerContent.phone}
              </p>
              <p>
                <span className="title">Location: </span>
                {footerContent.location}
              </p>
              <p>
                <span className="title">Address: </span>
                {footerContent.address}
              </p>
            </div>
          </div>
          <div className="footer-element col-md-4 quick-links">
            <div className="heading add-margin-bottom">
              <h4>Quick links</h4>
            </div>
            <div className="quick-links-content">
              <div className="add-margin-bottom">
                <a href="/">Home</a>
              </div>
              <div className="add-margin-bottom">
                <a href="/about">About Us</a>
              </div>
              <div className="add-margin-bottom">
                <a href="/our-services">Our Services</a>
              </div>
              <div className="add-margin-bottom">
                <a href="/careers">Careers</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
