import Service from "./Service";
import Slide from "react-reveal/Slide";
import serviceContent from "../../siteContent";
import "../../static/css/services.css";

const Services = () => (
  <section className="services-homepage">
    <div className="container">
      <Slide bottom>
        <div className="home-page-service-content">
          <div className="service-content heading">
            <h3>Our services</h3>
          </div>
          <div className="service-content subtext">
            <p>
              We offer an array of services under debt management industry
              vertical.
            </p>
          </div>
          <div className="service-listings">
            <div className="row">
              {serviceContent.services &&
                serviceContent.services.map((content) => {
                  const { icon, description, name, id } = content;
                  return (
                    <Service
                      icon={icon}
                      description={description}
                      name={name}
                      key={id}
                      id={id}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </Slide>
    </div>
  </section>
);

export default Services;
