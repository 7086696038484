import React from "react";
import Navbar from "../components/Header/Navbar";
import Footer from "../components/Footer";
import Slide from "react-reveal/Slide";
import NotFound from "../components/NotFound";

const NotFoundPage = () => {
  return (
    <main>
      <Navbar activePage="HomePage" />
      <section className="not-found-page">
        <NotFound pageView={true} />
      </section>
      <Slide bottom>
        <Footer />
      </Slide>
    </main>
  );
};

export default NotFoundPage;
