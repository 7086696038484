import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import $ from "jquery";
import React, { useState, useEffect } from "react";
import MobileMenu from "./MobileMenu";
import NavTrigger from "./NavTrigger";
import Logo from "../../static/images/logo.jpg";
import "../../static/css/navbar.css";

const Navbar = (props) => {
  //set default state
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const openMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  useEffect(() => {
    $(window).scroll(function () {
      if ($(document).scrollTop() > 1) {
        $(".site-header").addClass("affix");
      } else {
        $(".site-header").removeClass("affix");
      }
    });
  });

  // set media breakpoint for mobile devices
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <div id="masthead-sticky-wrapper" className="sticky-wrapper">
      <header id="masthead" className="site-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 col-sm-6 col-9">
              <React.Fragment>
                <div className="logo-section">
                  <img className="logo" src={Logo} alt="logo" />
                </div>
              </React.Fragment>
            </div>
            {isTabletOrMobile && (
              <div className="header-mobile-menu col-md-8 col-sm-6 col-3">
                <NavTrigger openMobileMenu={openMobileMenu} />
              </div>
            )}
            <nav className="site-navigation col-md-8">
              <div className="menu-container">
                <ul className="menu">
                  <li>
                    <a
                      href="/"
                      className={
                        props.activePage === "HomePage" ? "active" : ""
                      }
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="/about"
                      className={
                        props.activePage === "AboutPage" ? "active" : ""
                      }
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      href="/our-services"
                      className={
                        props.activePage === "OurServicesPage" ? "active" : ""
                      }
                    >
                      Our Services
                    </a>
                  </li>
                  <li>
                    <a
                      href="/careers"
                      className={
                        props.activePage === "CareersPage" ? "active" : ""
                      }
                    >
                      Careers
                    </a>
                  </li>
                  <li>
                    <a
                      href="/contact"
                      className={
                        props.activePage === "ContactPage" ? "active" : ""
                      }
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
              
            </nav>
          </div>
        </div>
      </header>
      {
        <MobileMenu
          mobileMenuOpen={mobileMenuOpen}
          activePage={props.activePage}
        />
      }
    </div>
  );
};

export default Navbar;
