import firebase from "firebase/app";
import 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCxvqPh0DxqvKp5kgo3mlfc52w6GJKMCVU",
  authDomain: "website-twinjays.firebaseapp.com",
  databaseURL: "https://website-twinjays.firebaseio.com",
  projectId: "website-twinjays",
  storageBucket: "website-twinjays.appspot.com",
  messagingSenderId: "850634262306",
  appId: "1:850634262306:web:1ee1082377be4e0b97877b",
  measurementId: "G-JD6FFGT2HQ"
};
// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} 

export default firebase;
