import React from "react";
import "../../static/css/navtrigger.css";

class NavTrigger extends React.Component {
  componentDidMount() {
    const navTrigger = document.getElementById("navTrigger");
    navTrigger.addEventListener("click", () => {
      navTrigger.classList.toggle("active");
      this.props.openMobileMenu();
    });
  }

  render() {
    return (
      <div id="navTrigger" className="navTrigger" tabIndex="-1">
        <i />
        <i />
        <i />
      </div>
    );
  }
}

export default NavTrigger;
