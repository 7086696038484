import Slide from "react-reveal/Slide";
import "../../static/css/jumbotron.css";

const Jumbotron = () => (
  <section className="jumbotron-custom">
    <Slide bottom>
      <div className="main-heading">
        <h1>Professional Debt Management.</h1>
      </div>
      <div className="sub-heading">
        <p>
          Twinjays Limited is trusted by 80% of the top 10 banking institutions
          in Kenya to provide complete debt recovery solutions.
        </p>
      </div>
      <div className="heading-button">
        <a href="/about" className="main-button" id="main-button">
          find out more
        </a>
      </div>
    </Slide>
  </section>
);

export default Jumbotron;
