import React from "react";
import "../../static/css/mobile-menu.css";
const MobileMenu = (props) => {
  return (
    <div
      className={
        props.mobileMenuOpen ? "mobile-menu-visible" : "mobile-menu-container"
      }
      id="mobile-menu-container"
    >
      <div className="menu-link">
        <a href="/" className={props.activePage === "HomePage" ? "active" : ""}>
          Home
        </a>
      </div>
      <div className="menu-link">
        <a
          href="/about"
          className={props.activePage === "AboutPage" ? "active" : ""}
        >
          About
        </a>
      </div>
      <div className="menu-link">
        <a
          href="/our-services"
          className={props.activePage === "OurServicesPage" ? "active" : ""}
        >
          Our Services
        </a>
      </div>
      <div className="menu-link">
        <a
          href="/careers"
          className={props.activePage === "CareersPage" ? "active" : ""}
        >
          Careers
        </a>
      </div>
      <div className="menu-link">
        <a
          href="/contact"
          className={props.activePage === "ContactPage" ? "active" : ""}
        >
          Contact Us
        </a>
      </div>
    </div>
  );
};

export default MobileMenu;
