import { useMediaQuery } from "react-responsive";
import { useState, useEffect } from "react";
import servicesContent from "../../siteContent";
import CallCenterLogo from "../../static/images/call-center.jpg";
import DebtCollectionLogo from "../../static/images/debt-collection.jpg";
import InvestigationLogo from "../../static/images/investigation.jpg";
import AnalyticsLogo from "../../static/images/analytics.jpg";
import "../../static/css/our-services.css";

const ServiceItem = (props) => {
  const [renderImageTop, setRenderImageTop] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  useEffect(() => {
    isMobile ? setRenderImageTop(true) : setRenderImageTop(false);
  });

  return (
    <div
      className="service-item"
      style={{
        "border-radius": "5px",
      }}
    >
      <div
        className="content"
        style={{
          "border-radius": "5px",
        }}
      >
        <div
          className="container"
          style={{
            "border-radius": "5px",
          }}
        >
          {!props.reversed || renderImageTop ? (
            <div
              className="row"
              style={{
                "border-radius": "5px",
              }}
            >
              <div
                className="service-icon col-sm-12 col-md-5"
                style={{
                  background: `url(${
                    props.id === 100
                      ? DebtCollectionLogo
                      : props.id === 101
                      ? AnalyticsLogo
                      : props.id === 102
                      ? InvestigationLogo
                      : CallCenterLogo
                  }) no-repeat center center`,
                  backgroundSize: "cover",
                  borderTopLeftRadius: "5px",
                  borderBottomLeftRadius: "5px",
                  marginBottom: "-1px",
                }}
              ></div>
              <div
                className="description col-sm-12 col-md-7"
                style={{
                  "border-top-right-radius": "5px",
                  "border-bottom-right-radius": "5px",
                }}
              >
                <div className="title add-margin-bottom">
                  <h3>{props.title}</h3>
                </div>
                {props.description.map((desc) => {
                  return <p>{desc}</p>;
                })}
              </div>
            </div>
          ) : (
            <div className="row">
              <div
                className="description col-sm-12 col-md-7"
                style={{
                  "border-top-left-radius": "5px",
                  "border-bottom-left-radius": "5px",
                }}
              >
                <div className="title add-margin-bottom">
                  <h3>{props.title}</h3>
                </div>
                {props.description.map((desc) => {
                  return <p>{desc}</p>;
                })}
              </div>
              <div
                className="service-icon col-sm-12 col-md-5"
                style={{
                  background: `url(${
                    props.id === 100
                      ? DebtCollectionLogo
                      : props.id === 101
                      ? AnalyticsLogo
                      : props.id === 102
                      ? InvestigationLogo
                      : CallCenterLogo
                  }) no-repeat center center`,
                  backgroundSize: "cover",
                  borderTopRightRadius: "5px",
                  borderBottomRightRadius: "5px",
                  marginBottom: "-1px",
                }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Services = () => (
  <section className="our-services-section">
    <div className="container">
      <div className="service-items-content row">
        {servicesContent.mainServices.map((content) => {
          const { id, reversed, title, icon, description } = content;
          return (
            <ServiceItem
              key={id}
              id={id}
              reversed={reversed}
              title={title}
              icon={icon}
              description={description}
            />
          );
        })}
      </div>
    </div>
  </section>
);

export default Services;
