import Choice from "./Choice";
import choicesContent from "../../siteContent";
import Slide from "react-reveal/Slide";
import "../../static/css/choices.css";

const Choices = () => (
  <section className="choices-section">
    <div className="container">
      <Slide bottom>
        <div className="choices-homepage-content">
          <div className="choices-element add-margin-bottom heading">
            <h3>Why choose us?</h3>
          </div>
          <div className="choices-element choices-listings">
            <div className="row">
              {choicesContent.choices &&
                choicesContent.choices.map((choice) => {
                  const { name, description, id } = choice;
                  return (
                    <Choice
                      name={name}
                      description={description}
                      key={id}
                      id={id}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </Slide>
    </div>
  </section>
);

export default Choices;
