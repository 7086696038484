import React from "react";
import firebase from "../../configs/firebase";
import "../../static/css/contact-form.css";
import ContactModal from "./ContactModal";
import Spinner from "react-bootstrap/Spinner";
import "bootstrap/dist/css/bootstrap.min.css";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      subject: "",
      email: "",
      message: "",
      loading: false,
      showContactModal: false,
      success: false,
    };
  }

  handleModalHide = () => {
    this.setState({
      showContactModal: false,
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  notify = (status) => {
    this.setState({
      loading: false,
    });
    switch (status) {
      case "success":
        this.setState({
          success: true,
          showContactModal: true,
          loading: false
        });
        break;
      default:
        this.setState({
          success: false,
          showContactModal: true,
          loading: false
        });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const sendMail = firebase
      .app()
      .functions("us-central1")
      .httpsCallable("sendMail");

    sendMail({ details: this.state })
      .then((res) => {
        this.notify("success");
      })
      .catch((error) => {
        this.notify("fail");
      });
  };

  render() {
    return (
      <div className="contact-form add-margin-bottom">
        <ContactModal
          show={this.state.showContactModal}
          onHide={this.handleModalHide}
          success={this.state.success}
        />
        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Your Name</label>
            <input
              type="text"
              name="name"
              className="form-control"
              id="name"
              placeholder="Enter name"
              onChange={(e) => this.handleChange(e)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Your Email</label>
            <input
              type="email"
              className="form-control"
              name="email"
              id="email"
              aria-describedby="emailHelp"
              placeholder="Enter email"
              onChange={(e) => this.handleChange(e)}
              required
            />
            <small id="emailHelp" className="form-text text-muted">
              We'll never share your email with anyone else.
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Subject</label>
            <input
              type="text"
              className="form-control"
              id="subject"
              name="subject"
              placeholder="Add subject"
              onChange={(e) => this.handleChange(e)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Your message</label>
            <textarea
              className="form-control"
              name="message"
              onChange={(e) => this.handleChange(e)}
              id="message"
              rows="3"
            ></textarea>
          </div>

          <button type="submit" className="submit-form-btn">
            {this.state.loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            <span
              style={{ "margin-left": `${this.state.loading ? "6px" : "0"}` }}
            >
              send
            </span>
          </button>
        </form>
      </div>
    );
  }
}

export default ContactForm;
