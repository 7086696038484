import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import SuccessImg from "../../static/images/success.svg";
import ErrorImg from "../../static/images/error.svg";

const ContactModal = (props) => (
  <Modal
    {...props}
    size="sm"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
        <span>{props.success ? "Success!" : "Oops! Something went wrong."}</span>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="modal-image" style={{ "margin-bottom": "1rem" }}>
        <img
          className="status-image"
          src={props.success ? SuccessImg : ErrorImg}
          alt="status image"
          width="200"
        />
      </div>
      <p>
        {props.success
          ? "Your message was successfully sent."
          : "Your message was not sent. Please try again."}
      </p>
    </Modal.Body>
    <Modal.Footer>
      <button className="close-modal-btn" onClick={props.onHide}>
        Close
      </button>
    </Modal.Footer>
  </Modal>
);

export default ContactModal;
