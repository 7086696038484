import ContactForm from "./ContactForm";
import "../../static/css/contact.css"
import contactDetails from "../../siteContent";
const Contact = () => (
  <section className="contact-section">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="contact-details">
            <div className="heading add-margin-bottom">
              <h3>Contact details</h3>
            </div>
            <div className="contact-company add-margin-bottom">
              <p>
                <span>Twinjays Limited.</span>
              </p>
              <p>
                <em>Your commercial business partner</em>
              </p>
            </div>
            <div className="location add-margin-bottom">
              <div className="contact-heading">
                <h5>Location</h5>
              </div>
              <div className="contact-content">
                <p>{contactDetails.location}</p>
              </div>
            </div>
            <div className="mail add-margin-bottom">
              <div className="contact-heading">
                <h5>Mail</h5>
              </div>
              <div className="contact-content">
                <p>{contactDetails.email}</p>
              </div>
            </div>
            <div className="phone add-margin-bottom">
              <div className="contact-heading">
                <h5>Phone</h5>
              </div>
              <div className="contact-content">
                <p>{contactDetails.phone}</p>
              </div>
            </div>
            <div className="address add-margin-bottom">
              <div className="contact-heading">
                <h5>Address</h5>
              </div>
              <div className="contact-content">
                <p>{contactDetails.address}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="heading add-margin-bottom">
            <h3>Get in touch</h3>
          </div>
          <ContactForm />
        </div>
      </div>
    </div>
  </section>
);

export default Contact;
