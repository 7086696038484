import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Header/Navbar";
import WhoWeAre from "../components/AboutPage/WhoWeAre";
import Mission from "../components/AboutPage/Mission";
import Ethics from "../components/AboutPage/Ethics";
import Slide from "react-reveal/Slide";

const AboutPage = () => (
  <main>
    <Helmet>
      <title>About Us</title>
      <meta
        name="description"
        content="Twinjays Ltd is a Commercial Debt Collection Agency based in Nairobi, Kenya. FIL has a combined total of over 20 years of hands-on debt collection experience through its key staff and management personnel, and directors. We are dedicated to be your full service debt collection solution by increasing the percentage of bad debt recovery for our clients in a professional manner, and preserving our client’s image and our reputation in our industry."
      />
    </Helmet>
    <Navbar activePage="AboutPage" />
    <WhoWeAre />
    <Slide bottom>
      <Mission />
    </Slide>
    <Slide bottom>
      <Ethics />
    </Slide>
    <Slide bottom>
      <Footer />
    </Slide>
  </main>
);

export default AboutPage;
