import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import careerListings from "../../siteContent";
import Listing from "./Listing";
import "../../static/css/careers.css";

const Careers = () => (
  <section className="careers-section">
    <div className="container">
      <div className="row">
        <div className="listings-description col-md-12">
          <div className="heading add-margin-bottom">
            <h3>Why Work at Twinjays Ltd?</h3>
          </div>
          <div className="description">
            <ol>
              {careerListings.whyWork.map((item) => {
                return <li key={item}>{item}</li>;
              })}
            </ol>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="job-listings col-md-12">
          <div className="heading add-margin-bottom">
            <h3>Current Openings</h3>
          </div>
          <div className="listings add-margin-bottom">
            <Accordion>
              {Object.keys(careerListings.jobListings).map((listing) => {
                const { title, id, slug } = careerListings.jobListings[
                  listing
                ].jobHeading;

                return (
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey={id.toString()}
                      >
                        <h6>{title}</h6>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={id.toString()}>
                      <Card.Body>
                        <Listing jobListing={careerListings.jobListings[listing]}/>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                );
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Careers;
