import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "../../static/css/mission-item.css";

const MissionItem = (props) => (
  <div className="mission-item col-md-4">
    <div className="title add-margin-bottom">
      <h3>{props.title}</h3>
    </div>
    <div className="description">
      {Array.isArray(props.description) ? (
        <div>
          {props.description.map((desc) => {
            return (
              <div>
                <span>
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{
                      transition: "all 0.5s ease-in-out",
                      color: "#0f5ca1",
                      fontSize: "1rem",
                    }}
                  />{" "}
                  {desc}
                </span>
              </div>
            );
          })}
        </div>
      ) : (
        props.description
      )}
    </div>
    <style jsx>{`
      .mission-item {
        margin-bottom: 20px;
      }

      @media (max-width: 768px) {
        .mission-item {
          text-align: center;
        }
      }
      .title h3 {
        padding: 0;
        margin: 0;
        line-height: 1;
        color: #191919;
      }
    `}</style>
  </div>
);

export default MissionItem;
