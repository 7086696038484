const siteContent = {
  companyName: "Twinjays Limited",
  location: "Ramis Centre, Victoria Courts Compound, Mombasa Road",
  slogan:
    "Your commercial business partner offering complete debt management solutions.",
  copyright: "@2019 Twinjays Ltd. All rights reserved.",
  email: "info@twinjaysltd.com",
  phone: "(+254) 789 055 150",
  address: "P.O. Box 27822-00100 Nairobi",

  services: [
    {
      id: 10,
      name: "Debt Collection",
      description:
        "Entrust us with your long overdue accounts while you concentrate on the growth of your business.",
      icon: "debt-collection.png",
    },
    {
      id: 11,
      name: "Contact/Delivery Centre",
      description:
        "We offer top notch contact centre solutions - high quality delivery standards.",
      icon: "call-center.png",
    },
    {
      id: 12,
      name: "Debt Scoring Analysis",
      description:
        "We help financial institutions and lenders to assess and analyze a customers' creditworthiness.",
      icon: "debt-scoring.png",
    },
    {
      id: 13,
      name: "Skip Tracing/Investigation",
      description:
        "We provide effective and efficient debtor investigation services following all leads to provide complete data about your debtors.",
      icon: "skip-tracing.png",
    },
  ],
  choices: [
    {
      id: 1,
      name: "Use of Technology",
      description:
        {__html: "<p>We have a well established call centre utilizing modern telephony technologies and data analytics to ensure that we are able to monitor all calls and track performance in real time.</p>"}
    },
    {
      id: 2,
      name: "Well-trained team",
      description:
        {__html: "<p>We take great care in the hiring and training of all personnel to assure that professionalism is maintained at all levels</p><p>We only hire professional collectors with extensive collection experience.</p>"}
    },
    {
      id: 3,
      name: "Customer-first Approach",
      description:
        {__html: "<p>While our primary objective is to collect the monies, we are also concerned with maintaining the continued goodwill of our clients.</p><p>We have a strict code of ethics in force which we constantly monitor in order to protect your reputation.</p>"},
    },
    {
      id: 4,
      name: "No collection, no charge",
      description:
        {__html: "<p>We try all we can to recover your debt.</p><p> However, if negotiations reach a stalemate and we are unable to recover your debt, then we shall not charge you subject to terms and conditions of the contract.</p>"},
    },
  ],
  mission: [
    {
      title: "Our Vision",
      description:
        "To continuously strive to be the leading provider of debt management solutions in Kenya, by developing beneficial and lasting relationships with our clients through a process of continuous improvement and optimizing their returns by delivering the most efficient and effective service.",
      id: 20,
    },
    {
      title: "Our Mission",
      description:
        "We are committed to provide comprehensive, integrated solutions for the management of credit risk, and improving the financial position of the business community.",
      id: 21,
    },
    {
      title: "Core Values",
      description: [
        "Superior Customer Service",
        "Commitment",
        "Confidentiality",
        "Innovation",
      ],
      id: 23,
    },
  ],
  ethics: [
    {
      id: 70,
      title: "Ethics",
      description:
        "Twinjays takes great care in the hiring and training of all personnel to assure that professionalism is maintained on all levels. We have a strict code of ethics in force which we constantly monitor in order to protect your best interest as our client.",
    },
    {
      id: 71,
      title: "Considerate Pricing",
      description:
        "We adopt a 'no collection no fee policy'. Our fee is based solely on debt collection results, not on the amount of time spent working on your account, nor on prolonged negotiations and mediation.",
    },
  ],
  mainServices: [
    {
      id: 100,
      reversed: false,
      title: "Debt Collection",
      description: [
        "As a full service nation debt collection agency, we help companies by collecting funds that are due to them from debtors in Kenya, and all over the world. We assist debtors come up with payment plans that will help them get their delinquent accounts paid in full.",
        "By so doing, our clients hold down the costs of debt collection to their accounts receivable departments.",
        "We offer a very competitive rate program which is quite pocket friendly to our clients.",
        "We have a strict code of ethics in force which we constantly monitor in order to protect your best interest as our client.",
      ],
      icon: "debt-collection.jpg",
    },
    {
      id: 101,
      reversed: true,
      title: "Debt Scoring Analysis",
      description: [
        "We help lenders to determine the risk involved when they are giving out loans to their customers. Our statistical methods which are based on payment records, frequency of payments, amounts of debts owing and charge-offs help determine the credit score of each customer.",
        "We usually do this with speed thus allowing our clients to be able to serve their customers quickly and impartially. Companies are able to avoid bad debts by utlizing our scoring analysis services.",
      ],
      icon: "analytics.jpg",
    },
    {
      id: 102,
      reversed: false,
      title: "Skip Tracing/Investigation",
      description: [
        "Our skip tracing methods and models help locate debtors who do not want to be found. This goes a long way in helping our clients to collect overdue accounts receivable.",
        "We also specialize on asset tracing, tailor made for each client according to their instruction to enable them to make informed decisions on the steps they need to take.",
        "Our investigation/tracing report summarizes all the findings including a debtor’s financial position which enables you to assess the prospects for a successful court action if court action if required.",
      ],
      icon: "investigation.jpg",
    },
    {
      id: 103,
      reversed: true,
      title: "Contact Centre/Delivery Centre",
      description: [
        "We offer top notch contact centre solutions – high quality delivery standards. Our aim is to add real value to your business while we grow together. ",
        "We do understand that answering a call while you endeavor to keep your daily operations running can be challenging.",
        "Of course ignoring the call can mean a huge loss of trust from your customers. Our business is to take that burden away from you. We offer both inbound and outbound call services, which means we can not only receive calls on your behalf but also make them.",
      ],
      icon: "call-center.jpg",
    },
  ],

  jobListings: {
    "customer-service-executive": {
      jobHeading: {
        id: 500,
        title: "Customer Service Executive",
        slug: "customer-service-executive",
        email: "careers@twinjaysltd.com",
        applyText: "If you are interested, send your resume/cv to",
      },
      description: {
        summary: {
          "Position Title": "Customer Service Executive",
          "Job Family": "Commercial – Customer Services",
          "Reports to": "Customer Service Team Leader",
          Date: "January 2020",
          "Function Level": "Entry",
        },
        details: {
          "Basic Purpose": [
            "To handle all Customer Care interactions by providing consistent standards of service to Client and ensuring customer satisfaction.",
            "To respond to all customer contacts by identifying, communicating and meeting customer needs, recording and resolving queries, in accordance with the pre-defined standards and measures, working within a team that is focused on providing quality service.",
            "May resolve simple and basic administrative support issues. For more complex issues are escalated to the Team Leader.",
            "Operates under general supervision.",
            "Being a link between the Customer and (client) by communicating the customer’s voice.",
          ],
          "Main Responsibilities and Duties:": [
            {
              title:
                "Develop positive relationships with customers and co-workers",
              description: [
                "Participate as an active member of the Customer Care team",
                "Communicate in a positive, professional style",
                "Take care of company equipment and tools",
                "Take responsibility for resolving customer queries",
                "Ensure security and confidentiality of information",
                "Be knowledgeable on all company & client related information",
              ],
            },
            {
              title: "Handle and resolve a range of customer contacts",
              description: [
                "Prepare to respond to customer contact within defined time-limits",
                "Acknowledge Customers and greet using the standard corporate greeting",
                "Elicit and verify information from customers (if applicable)",
                "Escalate service request",
                "Provide requested service",
                "Provide Information and/or educate customer",
              ],
            },
            {
              title: "Record a range of customer contacts",
              description: [
                "Record and capture customer details accurately",
                "Effectively retrieve information using appropriate systems",
                "Correctly classify all reasons for contacts",
              ],
            },
            {
              title: "Meet performance standards",
              description: [
                "Know and understand Customer Care Performance Standards and individual targets",
                "Meet the customer care service level and response time objectives as per the defined budget and segment",
                "Attend daily briefing sessions with team",
                "Document, analyze and interpret daily individual reports",
                "Provide daily feedback on performance in relation to pre-defined standards and measures",
                "Adhere to Customer Care (Outbound) schedule and professional attitude",
                "Escalate complex cases and liaises with The Team Leader to resolve customer queries and meet their expectations.",
              ],
            },
          ],
          "Additional Duties and Responsibilities:": [
            {
              title: "Make the Call Center customer centric.",
              description: [
                "Provide feedback and suggestions",
                "Participate in Team activities",
              ],
            },
            {
              title:
                "Promote clear and effective high performance culture and values.",
              description: [
                "Promate high performance culture.",
                "Promote customer centric culture.",
                "Promote culture of openness and transparency",
              ],
            },
          ],
          "Essential Knowledge": [
            {
              title:
                "Good functional knowledge of most product lines. Good working knowledge of office procedures and general customer service standards.",
              description: [],
            },
            {
              title:
                "General to good knowledge; frequent use and application of technical standards principles, theories, concepts and techniques.",
              description: [],
            },
            {
              title: "Competencies",
              description: [
                "Neutral/American English",
                "Customer oriented",
                "Strong analytical skills and problem solving skills",
                "High personal standards and goal oriented",
                "Excellent interpersonal skills",
                "Excellent and effective communications skills; both orally and in writing, eloquent",
                "Excellent organizational skills",
                "Easy adaptability to change",
                "Young spirited; energetic and enthusiastic",
                "Team Player",
                "Works well under pressure",
              ],
            },
            {
              title: "Qualifications",
              description: [
                "University Degree/Diploma in Business",
                "5 years work experience",
                "IT literacy",
                "Able to operate in a performance driven organization",
                "Knowledge of English and Swahili",
              ],
            },
          ],
        },
      },
    },
    "call-centre-officer": {
      jobHeading: {
        id: 501,
        title: "Call Centre Officer – Debt Collection",
        slug: "call-centre-officer",
        email: "careers@twinjaysltd.com",
        applyText: "If you are interested, send your resume/cv to",
      },

      description: {
        summary: {
          "Function / Department": "Call Center",
          "Reports To": "Team Manager",
          "Job Purpose":
            "To ensure timely collection of outstanding debts as per agreed terms and limits.",
        },
        details: {
          "Key Responsibilities": [
            "Perform various duties on assigned accounts such as recording, verification and validation of customer’s details as received from clients.",
            "Advise customer on ways of clearing their debts while communicating with them on the importance of paying their debts and consequences of non-payment.",
            "Act as a mediator for disputes between customers and principals/Clients",
            "Locate and notify customers on defaulted accounts by mail, telephone, or scheduled visits in order to ask for payments",
            "Deliberate with customers by telephone or in-person to determine reasons for overdue payments and to review the credit terms for easier settlement of debts.",
            "Initiate the clearance process on cleared debts once you receive payment proof and have the same uploaded by the relevant department.",
            "Provide customers with their correct account details e.g. balances, account numbers etc.",
            "Maintaining data integrity and keep customer details confidential.",
            "Attend to customers during scheduled/unscheduled meetings.",
            "Record information about the financial status of customers and status of collection efforts in the company’s system.",
            "Respond to all correspondences e.g. e-mail, telephone, postal mail.",
            "Organize, manage and implement the most suitable debt recovery method and techniques.",
            "Negotiate credit extensions if and when required.",
            "Preparation of reports as required.",
          ],
          Qualifications: [
            "Possession of a Diploma in any field from a recognized institution with at least 1-2 years’ experience in Collection/Credit or Sales and Marketing environment (Diploma (Banking/Credit management background will have an added advantage).",
            "Strong analytical power, good problem-solving skills and ability to work under immense pressure.",
            "Good communication and interpersonal skills to manage stakeholders.",
            "Fluency in both spoken and written English is a must.",
            "Computer literate & English proficiency.",
          ],
        },
      },
    },
  },
  whyWork: [
    "We have a diverse talented team from all over Kenya.",
    "Our core values – Innovation, Commitment, Confidentiality, and Superior Customer Service.",
  ],
};

export default siteContent;
