import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Header/Navbar";
import Jumbotron from "../components/HomePage/Jumbotron";
import Chart from "../components/HomePage/Chart";
import Intouch from "../components/HomePage/Intouch";
import Services from "../components/HomePage/Services";
import Choices from "../components/HomePage/Choices";
import Clients from "../components/HomePage/Clients";
import Footer from "../components/Footer";
import Slide from "react-reveal/Slide";

const HomePage = () => {
  return (
    <main>
      <Helmet>
        <title>Home</title>
        <meta
          name="description"
          content="We put people first, doing everything professionally to get your money back while still preserving a good relationship with your customer. We combine a customer-centric approach with cutting edge technology solutions to provide great contact management."
        />
      </Helmet>
      <Navbar activePage="HomePage" />
      <Jumbotron />
      <Chart />
      <Intouch />
      <Services />
      <Choices />
      <Slide bottom>
        <Clients />
      </Slide>
      <Slide bottom>
        <Footer />
      </Slide>
    </main>
  );
};

export default HomePage;
