import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Header/Navbar";
import Contact from "../components/Contact";
import Slide from "react-reveal/Slide";

const ContactPage = () => (
  <main>
    <Helmet>
      <title>Contact Us</title>
      <meta
        name="description"
        content="Get in touch with us."
      />
    </Helmet>
    <Navbar activePage="ContactPage" />
    <Contact />
    <Slide bottom>
      <Footer />
    </Slide>
  </main>
);

export default ContactPage;
